import React from 'react'
import PropTypes from 'prop-types'

import { PhoneIcon, MailIcon, ClockIcon } from '@heroicons/react/outline'

function GetInTouch({ email, phone, phoneDisplay }) {
  return (
    <div className="bg-gray-light text-white pt-12 sm:pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold sm:text-4xl">
            We have completed 360+ projects
          </h2>
          <p className="mt-3 text-xl sm:mt-4">
            Get in touch today to arrange a free quote.
          </p>
        </div>
      </div>
      <div className="mt-10 pb-12 sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-green-light">
                    <a href={`tel:${phone}`}>{phoneDisplay}</a>
                  </dt>
                  <dd className="flex justify-center items-center order-1 text-2xl uppercase font-bold">
                    <PhoneIcon className="h-6 w-6 mx-1" aria-hidden="true" />
                    <span>Call Us</span>
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-green-light">
                    <a href={`mailto:${email}`}>{email}</a>
                  </dt>
                  <dd className="flex justify-center items-center order-1 text-2xl uppercase font-bold">
                    <MailIcon className="h-6 w-6 mx-1" aria-hidden="true" />
                    <span>Email Us</span>
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-green-light">
                    Mon - Fri: 8am - 6pm
                  </dt>
                  <dd className="flex justify-center items-center order-1 text-2xl uppercase font-bold">
                    <ClockIcon className="h-6 w-6 mx-1" aria-hidden="true" />
                    <span>Opening Hours</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

GetInTouch.propTypes = {
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  phoneDisplay: PropTypes.string.isRequired,
}

export default GetInTouch
