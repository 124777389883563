import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

import Header from '../Header'
import Footer from '../Footer'

function Layout({ backgroundImage, accent, children }) {
  return (
    <>
      {backgroundImage ? (
        <aside className="absolute top-0 right-0 left-0 -z-1">
          <StaticImage
            src="../../images/lawn.png"
            alt="Background image"
            aspectRatio={16 / 9}
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        </aside>
      ) : null}
      <Header accent={accent} />
      <main className={backgroundImage ? 'mt-16 lg:mt-96' : null}>
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  backgroundImage: PropTypes.bool,
  accent: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  backgroundImage: false,
  accent: false,
}

export default Layout
