import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

function ServicesPopover() {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="group rounded-md inline-flex items-center text-base font-medium focus:outline-none focus:ring focus:ring-offset-4 focus:ring-offset-gray focus:ring-white">
            <span>Services</span>
            <ChevronDownIcon className="ml-2 h-5 w-5 " aria-hidden="true" />
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
            >
              <div className="rounded-lg shadow-lg text-white bg-gray ring-1 ring-gray-light overflow-hidden">
                <ul className="px-5 py-6 space-y-4">
                  <li>
                    <Link
                      className="block hover:text-green"
                      activeClassName="text-green"
                      to="/lawn-installation/"
                    >
                      Lawn Installation
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block hover:text-green"
                      activeClassName="text-green"
                      to="/fencing/"
                    >
                      Fencing
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block hover:text-green"
                      activeClassName="text-green"
                      to="/clearance-and-hedges/"
                    >
                      Clearance &amp; Hedges
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block hover:text-green"
                      activeClassName="text-green"
                      to="/patio-and-decking/"
                    >
                      Patio &amp; Decking
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block hover:text-green"
                      activeClassName="text-green"
                      to="/composite-decking-installation/"
                    >
                      Composite Decking
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block hover:text-green"
                      activeClassName="text-green"
                      to="/artificial-turf/"
                    >
                      Artificial Turf
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block hover:text-green"
                      activeClassName="text-green"
                      to="/grounds-maintenance/"
                    >
                      Grounds Maintenance
                    </Link>
                  </li>
                </ul>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

function Header({ accent }) {
  return (
    <header className="relative">
      <Popover
        className={clsx(
          'mt-4 lg:mt-8 relative text-white bg-gray shadow',
          accent && 'border-b-8 border-green'
        )}
      >
        {({ open }) => (
          <>
            <div className="max-w-5xl mx-auto px-4 sm:px-6">
              <div className="flex justify-between items-center md:justify-start md:space-x-10 h-24">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <Link to="/">
                    <span className="sr-only">ProGro Landscapes</span>
                    <StaticImage
                      src="../../images/logo.png"
                      height={72}
                      alt=""
                      loading="eager"
                      placeholder="none"
                    />
                  </Link>
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center focus:outline-none focus:ring focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <Popover.Group as="nav" className="hidden md:flex space-x-10">
                  <Link
                    to="/"
                    className="hover:text-green"
                    activeClassName="text-green"
                  >
                    Home
                  </Link>
                  <Link
                    to="/about-us/"
                    className="hover:text-green"
                    activeClassName="text-green"
                  >
                    About Us
                  </Link>
                  <ServicesPopover />
                  <Link
                    to="/developers/"
                    className="hover:text-green"
                    activeClassName="text-green"
                  >
                    Commercial
                  </Link>
                  <Link
                    to="/contact/"
                    className="hover:text-green"
                    activeClassName="text-green"
                  >
                    Contact
                  </Link>
                </Popover.Group>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg text-white bg-gray ring-1 ring-gray-light divide-y-2 divide-white">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <h2>Services</h2>
                      <div className="-mr-2">
                        <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white focus:outline-none focus:ring focus:ring-inset focus:ring-white">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <nav>
                      <ul className="p-4 space-y-4">
                        <li>
                          <Link
                            className="hover:text-green"
                            activeClassName="text-green"
                            to="/lawn-installation/"
                          >
                            Lawn Installation
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="hover:text-green"
                            activeClassName="text-green"
                            to="/fencing/"
                          >
                            Fencing
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="hover:text-green"
                            activeClassName="text-green"
                            to="/clearance-and-hedges/"
                          >
                            Clearance &amp; Hedges
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="hover:text-green"
                            activeClassName="text-green"
                            to="/patio-and-decking/"
                          >
                            Patio &amp; Decking
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="hover:text-green"
                            activeClassName="text-green"
                            to="/composite-decking-installation/"
                          >
                            Composite Decking
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="hover:text-green"
                            activeClassName="text-green"
                            to="/artificial-turf/"
                          >
                            Artificial Turf
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="hover:text-green"
                            activeClassName="text-green"
                            to="/grounds-maintenance/"
                          >
                            Grounds Maintenance
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                      <Link
                        to="/"
                        className="hover:text-green"
                        activeClassName="text-green"
                      >
                        Home
                      </Link>
                      <Link
                        to="/about-us/"
                        className="hover:text-green"
                        activeClassName="text-green"
                      >
                        About Us
                      </Link>
                      <Link
                        to="/developers/"
                        className="hover:text-green"
                        activeClassName="text-green"
                      >
                        Commercial
                      </Link>
                      <Link
                        to="/contact/"
                        className="hover:text-green"
                        activeClassName="text-green"
                      >
                        Contact
                      </Link>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </header>
  )
}

Header.propTypes = {
  accent: PropTypes.bool,
}

Header.defaultProps = {
  accent: false,
}

export default Header
