import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { SocialIcon } from 'react-social-icons'

import GetInTouch from '../GetInTouch'

function Footer() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          phone
          phoneDisplay
        }
      }
    }
  `)

  const { email, phone, phoneDisplay } = site.siteMetadata

  return (
    <footer aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <GetInTouch email={email} phone={phone} phoneDisplay={phoneDisplay} />
      <div className="space-y-8 max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-12">
          <div className="space-y-8 xl:col-span-1">
            <div className="space-y-4">
              <h3 className="text-sm font-semibold tracking-wider uppercase">
                Trained and Experienced
              </h3>
              <p>
                All directors and senior members hold various qualifications in
                landscaping and have gained Health and Safety accreditation.
              </p>
            </div>
            <div className="flex space-x-6">
              <SocialIcon url="https://www.linkedin.com/company/progro-landscapes" />
              <SocialIcon url="https://www.facebook.com/ProGroLandscapes/" />
              <SocialIcon url="https://instagram.com/progrolandscapes" />
            </div>
            <iframe
              title="Bark.com reviews"
              src="https://www-progrolandscapes-co-uk.filesusr.com/html/59948c_a81d57521e97b416c22dd6ffd718863d.html"
            />
          </div>
          <div className="mt-12 md:mt-0">
            <h3 className="text-sm font-semibold tracking-wider uppercase">
              Our Services
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link className="hover:text-green" to="/lawn-installation/">
                  Lawn Installation
                </Link>
              </li>
              <li>
                <Link className="hover:text-green" to="/fencing/">
                  Fencing
                </Link>
              </li>
              <li>
                <Link className="hover:text-green" to="/clearance-and-hedges/">
                  Clearance &amp; Hedges
                </Link>
              </li>
              <li>
                <Link className="hover:text-green" to="/patio-and-decking/">
                  Patio &amp; Decking
                </Link>
              </li>
              <li>
                <Link
                  className="hover:text-green"
                  to="/composite-decking-installation/"
                >
                  Composite Decking
                </Link>
              </li>
              <li>
                <Link className="hover:text-green" to="/artificial-turf/">
                  Artificial Turf
                </Link>
              </li>
              <li>
                <Link className="hover:text-green" to="/grounds-maintenance/">
                  Grounds Maintenance
                </Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4 mt-12 md:mt-0">
            <h3 className="text-sm font-semibold tracking-wider uppercase">
              Contact Us
            </h3>
            <p>
              Our registered office:
              <br />
              16 East Stratton Close,
              <br />
              Martins Heron,
              <br />
              Bracknell RG12 0XY
            </p>
            <p>
              <a className="hover:text-green" href={`tel:${phone}`}>
                {phoneDisplay}
              </a>
            </p>
            <p>
              <a className="hover:text-green" href={`mailto:${email}`}>
                {email}
              </a>
            </p>
            <StaticImage
              src="../../images/logo-light.jpg"
              alt="ProGro Landscapes"
              placeholder="none"
            />
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="xl:text-center">
            &copy; 2018 - {new Date().getFullYear()} ProGro Landscapes Ltd. All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
