import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import url from 'url'

const createOpenGraphData = (config, title, canonical, image) => ({
  title,
  url: canonical,
  type: 'website',
  locale: 'en_GB',
  images: [
    {
      url: url.resolve(config.siteUrl, getSrc(image)),
      width: getImage(image).width,
      height: getImage(image).height,
      alt: 'Featured work',
    },
  ],
})

function Meta({ title, description, location, image }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
      file(relativePath: { eq: "lawn.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const config = data.site.siteMetadata
  const defaultImage = data.file

  const canonical = url.resolve(config.siteUrl, location.pathname)

  return (
    <GatsbySeo
      language="en"
      title={title}
      description={description}
      canonical={canonical}
      titleTemplate={`%s | ${config.title}`}
      twitter={{ cardType: 'summary_large_image' }}
      openGraph={createOpenGraphData(
        config,
        title,
        canonical,
        image || defaultImage
      )}
    />
  )
}

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  image: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
}

Meta.defaultProps = {
  image: null,
}

export default Meta
